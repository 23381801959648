'use strict';

angular.module('enervexSalesappApp').factory('AccountRequestComment', function ($resource) {
	return $resource('/api/account-requests/:accountRequestId/comments/:id', {
		id: '@_id'
	},
	{
		update: {
			method: 'PUT'
		}
	});
});




